import "./styles.css";

import PartySocket from "partysocket";

declare const PARTYKIT_HOST: string;

let pingInterval: ReturnType<typeof setInterval>;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;
  
var player1textbox = document.createElement('input');
player1textbox.type = 'player1id';

output.appendChild(document.body.appendChild(player1textbox));
output.appendChild(document.createElement("br"));


// Helper function to add a new line to the DOM
function add(text: string) {
  output.appendChild(document.createTextNode(text));
  output.appendChild(document.createElement("br"));
}

let buttonElement = document.createElement("button");
buttonElement.textContent = "JOIN";
buttonElement.addEventListener('click', function() {
  conn.send(JSON.stringify({ type: "join", id: player1textbox.value, name: "prasad", bowler1ID: 1, bowler1Level: 1, bowler2ID: 1, bowler2Level: 1, bowler3ID: 1, bowler3Level: 1, batsmanID: 99, batsmanLevel: 1, ballID: 1, spectator: 0, countryCode: "IN"}));
  });
document.body.appendChild(buttonElement);

let bowlbuttonElement = document.createElement("button");
bowlbuttonElement.textContent = "BOWL";
bowlbuttonElement.addEventListener('click', function() {
  conn.send(JSON.stringify({ "type":"bowl","id": player1textbox.value,"x":-0.393579870462418,"y":0.0309999994933605,"z":7.63229370117188,"bowltype":0,"bowlspeed":1.47055685520172,"bowlspin":0,"bowlswing":0,"playerID":0,"ballCount":1,"isLegal":5 }));
  });
document.body.appendChild(bowlbuttonElement);

let batbuttonElement = document.createElement("button");
batbuttonElement.textContent = "BAT";
batbuttonElement.addEventListener('click', function() {
  conn.send(JSON.stringify({ "type":"bat","id": player1textbox.value,"x":0,"y":0,"z":0,"animation":-1,"outcome":20,"playerID":0,"height":0,"rTime":0,"totalScore":0 }));
  });
document.body.appendChild(batbuttonElement);

let batchangebuttonElement = document.createElement("button");
batchangebuttonElement.textContent = "BATCHANGE";
batchangebuttonElement.addEventListener('click', function() {
  conn.send(JSON.stringify({ type: "batchange", id: player1textbox.value, x: 1, y: 1, z: 1, animation: 1, batsmanID: 1}));
  });
document.body.appendChild(batchangebuttonElement);

let resetbuttonElement = document.createElement("button");
resetbuttonElement.textContent = "RESET";
resetbuttonElement.addEventListener('click', function() {
  conn.send(JSON.stringify({ type: "reset"}));
  });
document.body.appendChild(resetbuttonElement);

let joinlobbybuttonElement = document.createElement("button");
joinlobbybuttonElement.textContent = "JOINLOBBY";
joinlobbybuttonElement.addEventListener('click', function() {
  conn.send(JSON.stringify({ type: "joinlobby", id: player1textbox.value, name: "prasad", level: 1}));
  });
document.body.appendChild(joinlobbybuttonElement);

let resetlobbybuttonElement = document.createElement("button");
resetlobbybuttonElement.textContent = "RESETLOBBY";
resetlobbybuttonElement.addEventListener('click', function() {
  conn.send(JSON.stringify({ type: "resetlobby"}));
  });
document.body.appendChild(resetlobbybuttonElement);


// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "1000"
});

conn.addEventListener("error", (event) => {
  add("Error:" + event.error + "::" + event.message );
})

// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  add(`Received -> ${event.data}`);
  if (typeof PARTYKIT_HOST !== 'undefined')
    add(PARTYKIT_HOST);
});

// // Let's listen for when the connection opens
// // And send a ping every 2 seconds right after
// conn.addEventListener("type", () => {
//   add("Connected!");
  // add("Sending a ping every 2 seconds...");
  // // TODO: make this more interesting / nice
  // clearInterval(pingInterval);
  // pingInterval = setInterval(() => {
  //   add("sending bowl message");
  //   conn.send(JSON.stringify({ type: "bowl", id: "123", x: 1, y: 1, z: 1, bowltype: 1, bowlspeed: 1, bowlspin: 1, playerID: 1, ballCount: 99}));
  // }, 1000000);
// });
